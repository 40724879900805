import { Navigate, useParams } from "react-router";
import PageContainer from "../../../components/common/PageContainer";
import { Box, Divider, Grid, Link, Tooltip, Typography } from "@mui/material";
import { useRegions } from "../../../hooks/utils/useRegions";
import {
  PostAvatar,
  PostAvatarContainer,
  PostCategoryLocationContainer,
  PostDetailsContainer,
  PostLocation,
  PostPersonalInfo,
  PostPersonalInfoContainer,
  PostPersonalInfoIconContainer,
  StyledListElement,
  ListContainer,
  StyledList,
  StyledDivider,
  SkillsAndServicesContainer,
  WorkExperienceInfo,
  PostHeader,
  RelatedPostsContainer,
} from "./styled";
import {
  Business,
  Circle,
  Email,
  Facebook,
  Flag,
  GitHub,
  Info,
  LinkedIn,
  LocationOn,
  Person,
  Phone,
  Twitter,
  Work,
} from "@mui/icons-material";
import { Post, WorkExperienceLevel } from "../../../api/posts/types";
import { useEmploymentStatusTypes } from "../../../hooks/utils/useEmploymentStatusTypes";

import Portfolios from "../components/Portfolios";
import dayjs from "dayjs";
import { useUser } from "../../../hooks/user/useUser";
import Button from "../../../components/Button";
import useSendFriendRequest from "../../../hooks/connections/useSendFriendRequest";
import useBreakpoints from "../../../hooks/utils/useBreakpoints";
import useGetPostById from "../../../hooks/posts/useGetPostById";
import { useEffect, useMemo, useState } from "react";
import useGetAcceptedRequests from "../../../hooks/connections/useGetAcceptedRequests";
import LinkWithIcon from "../../../components/LinkWithIcon";
import useGetRelatedPosts from "../../../hooks/posts/useGetRelatedPosts";
import WorkerCards from "../../Home/components/WorkerCards/WorkerCards";
import { useQuery } from "react-query";
import { getStatus } from "../../../api/connections";
import { getPostById } from "../../../api/posts";
import { SuccessResponse } from "../../../api/http";

const PostDetails = (user: any) => {
  const { id } = useParams();

  const { breakpoint } = useBreakpoints();

  const { data: regions } = useRegions();
  const { data: employmentStatusTypes } = useEmploymentStatusTypes();

  const sendFriendRequest = useSendFriendRequest();

  const [authenticated, setAuthenticated] = useState(false);
  useEffect(() => {
    if (user.user?.id) {
      setAuthenticated(true);
    }
  }, [user.user?.id]);

  // const { data, isLoading, isFetching } = useGetPostById( id as string, authenticated);
  const { data, isLoading, isFetching } = useQuery<SuccessResponse<Post>>(
    ["posts", authenticated],
    () => getPostById(id, authenticated),
  );

  const { data: { data: acceptedConnectionsData } = {} } = useGetAcceptedRequests();

  const post = data?.data;

  const postOwner = post?.user;
  const isOwnPost = post?.user_id === user?.user?.id;

  const hasSocialLinks = useMemo(
    () => !!post?.facebook || !!post?.twitter || !!post?.linkedin || !!post?.github,
    [post?.facebook, post?.twitter, post?.linkedin, post?.github],
  );

  const { data: connected, isLoading: connectedLoading } = useQuery([post, user], () =>
    getStatus(post?.id ? post?.id.toString() : null, user ? user.user.id.toString() : null),
  );
  const sentFriendRequest = useMemo(
    () =>
      post &&
      acceptedConnectionsData
        ?.filter((c) => c.status === "pending")
        .map((c) => (c.receiver_id === user?.id ? c.sender_id : c.receiver_id))
        .includes(post.user_id),
    [acceptedConnectionsData, user?.id, post],
  );

  const { data: relatedPostsData } = useGetRelatedPosts(`${post?.id}`);

  if (!post && !(isLoading || isFetching)) {
    return <Navigate to="/posts" />;
  }

  const relatedPosts = relatedPostsData as unknown as Post[] | undefined; // TODO: Fix BE response

  const postLocation = regions?.data.find((region) => region.id === post?.region_id);

  if (connectedLoading && authenticated) return <PageContainer>Loading...</PageContainer>;
  return (
    <PageContainer>
      <PostDetailsContainer>
        <Box width="100%">
          <PostAvatarContainer type={post?.user?.user_type ?? "user"}>
            <PostAvatar
              src={postOwner?.profile?.avatar || "https://talenti-static.s3.amazonaws.com/avatars/default_avatar.png"}
              alt="Profile picture"
            />
          </PostAvatarContainer>
          <PostHeader>
            <>
            <Grid container spacing={1} my={1}>
              <Grid item xs={12} md={10}>
            <Box>
                <Typography variant="h4" component="h1" fontWeight="bold">
                  {post?.title ?? "Анонимно"}
                </Typography>
                <PostCategoryLocationContainer>
                  <Typography variant="subtitle1">{post?.categoryTitle}</Typography>
                  <Circle sx={{ fontSize: "2px" }} />
                  <PostLocation>
                    <LocationOn />
                    <Typography variant="subtitle1">{postLocation?.name}</Typography>
                  </PostLocation>
                </PostCategoryLocationContainer>
              </Box>
              </Grid>
              <Grid item xs={4} md={2} >
              {!user?.user?.id && (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    textAlign: "center",
                  }}
                  disabled={sendFriendRequest.isLoading || sendFriendRequest.isSuccess}
                  to={!user?.user?.id ? "/login" : undefined}
                  fullWidth={false}
                  onClick={() =>
                    user?.user?.id &&
                    post?.id &&
                    sendFriendRequest.mutate({ postId: `${post?.id}` })
                  }
                >
                  Поврзи се
                </Button>
              )}

{!isOwnPost &&
                user?.user?.id &&
                !sentFriendRequest &&
                connected?.data?.status === "not_connected" && (
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      textAlign: "center",
                    }}
                    disabled={sendFriendRequest.isLoading || sendFriendRequest.isSuccess}
                    to={!user ? "/login" : undefined}
                    fullWidth={false}
                    onClick={() =>
                      user && post?.id && sendFriendRequest.mutate({ postId: `${post?.id}` })
                    }
                  >
                    Поврзи се
                  </Button>
                )}
              {!isOwnPost && connected?.data?.status === "accepted" && (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={true}
                  sx={{
                    textAlign: "center",
                  }}
                  to={!user ? "/login" : undefined}
                  aria-disabled
                  onClick={() =>
                    user && post?.id && sendFriendRequest.mutate({ postId: `${post?.id}` })
                  }
                >
                  Поврзан
                </Button>
              )}
              {!isOwnPost && connected?.data?.status === "pending" && (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={true}
                  sx={{
                    textAlign: "center",
                  }}
                  to={!user ? "/login" : undefined}
                  aria-disabled
                  onClick={() =>
                    user && post?.id && sendFriendRequest.mutate({ postId: `${post?.id}` })
                  }
                >
                  Испратена покана
                </Button>
              )}
              {isOwnPost && breakpoint !== "mobile" && (
                <Button
                  variant="contained"
                  color="primary"
                  to={`/posts/${post?.id}/edit`}
                  fullWidth={false}
                >
                  Измени
                </Button>
              )}
              </Grid>
              </Grid>
              
             
            </>
          </PostHeader>
        </Box>
        <Tooltip
          leaveTouchDelay={10000}
          enterTouchDelay={0}
          placement={breakpoint === "mobile" ? "bottom" : "bottom-start"}
          title={
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <Typography variant="body1">
                Анонимните податоци ќе ви бидат прикажани само доколку сте поврзани со корисникот
                кој го објавил огласот.
              </Typography>
              <Typography variant="body2">
                Доколку не сте поврзани, податоците ќе бидат скриени.
              </Typography>
            </Box>
          }
        >
          <Button
            variant="text"
            color="info"
            startIcon={<Info />}
            disableRipple
            sx={{
              cursor: "help",
              width: "max-content",
            }}
          >
            Анонимни податоци
          </Button>
        </Tooltip>
        <PostPersonalInfoContainer>
          <PostPersonalInfo>
            <Typography variant="subtitle1" color="GrayText">
              Телефонски број
            </Typography>
            <PostPersonalInfoIconContainer>
              <Phone />
              <Typography variant="subtitle2">{post?.phone ?? "Анонимно"}</Typography>
            </PostPersonalInfoIconContainer>
          </PostPersonalInfo>
          <StyledDivider orientation="vertical" />
          <PostPersonalInfo>
            <Typography variant="subtitle1" color="GrayText">
              Email адреса
            </Typography>
            <PostPersonalInfoIconContainer>
              <Email />
              <Typography variant="subtitle2">{post?.email ?? "Анонимно"}</Typography>
            </PostPersonalInfoIconContainer>
          </PostPersonalInfo>
          <StyledDivider orientation="vertical" />
          <PostPersonalInfo>
            <Typography variant="subtitle1" color="GrayText">
              Искуство
            </Typography>
            <PostPersonalInfoIconContainer>
              <Person />
              <Typography variant="subtitle2">{`${post?.experience_years} години`}</Typography>
            </PostPersonalInfoIconContainer>
          </PostPersonalInfo>
          <StyledDivider orientation="vertical" />
          <PostPersonalInfo>
            <Typography variant="subtitle1" color="GrayText">
              Година на раѓање
            </Typography>
            <PostPersonalInfoIconContainer>
              <Person />
              <Typography variant="subtitle2">
                {postOwner?.profile?.birthday
                  ? dayjs(postOwner?.profile?.birthday).format("YYYY")
                  : "Анонимно"}
              </Typography>
            </PostPersonalInfoIconContainer>
          </PostPersonalInfo>
          <StyledDivider orientation="vertical" />
          <PostPersonalInfo>
            <Typography variant="subtitle1" color="GrayText">
              Ниво на позиција
            </Typography>
            <PostPersonalInfoIconContainer>
              <Flag />
              <Typography variant="subtitle2">
                {post?.work_experience_level
                  ? Object.entries(WorkExperienceLevel).find(
                      ([, v]) => `${v}` === `${post?.work_experience_level}`,
                    )?.[0]
                  : "Анонимно"}
              </Typography>
            </PostPersonalInfoIconContainer>
          </PostPersonalInfo>
          <StyledDivider orientation="vertical" />
          <PostPersonalInfo>
            <Typography variant="subtitle1" color="GrayText">
              Тип на вработување
            </Typography>
            <PostPersonalInfoIconContainer>
              <Work />
              <Typography variant="subtitle2">
                {post?.employment_type.name ?? "Анонимно"}
              </Typography>
            </PostPersonalInfoIconContainer>
            <StyledDivider orientation="vertical" />
          </PostPersonalInfo>         
          {post?.current_company !== "" && (
            <>
              <StyledDivider orientation="vertical" />
              <PostPersonalInfo>
                <Typography variant="subtitle1" color="GrayText">
                  Моментална компанија
                </Typography>
                <PostPersonalInfoIconContainer>
                  <Business />
                  <Typography variant="subtitle2">{post?.current_company ?? "Анонимно"}</Typography>
                </PostPersonalInfoIconContainer>
                <StyledDivider orientation="vertical" />
              </PostPersonalInfo>
            </>
          )}
        </PostPersonalInfoContainer>

        <Divider />

        <Typography variant="h3" fontWeight="bold">
          Опис
        </Typography>

        <Typography variant="body1" gutterBottom>
          {post?.description}
        </Typography>

        <Typography gutterBottom>
          Очекувана плата:{" "}
          <Typography display="inline" color="primary" fontWeight="bold">
            {post?.salary} {post?.salary_currency || "MKD"}
          </Typography>
        </Typography>

        <Divider />

        {(!!post?.services?.length || !!post?.skills?.length) && (
          <>
            <SkillsAndServicesContainer>
              {!!post?.services?.length && (
                <ListContainer>
                  <>
                    <Typography variant="h6">Дополнителни Услуги</Typography>
                    <StyledList>
                      {post.services.map((service, index, arr) => (
                        <>
                          <StyledListElement key={service.name}>
                            <Typography variant="subtitle1">{service.name} / </Typography>
                            <Typography variant="subtitle1">{service.description} - </Typography>
                            <Typography variant="subtitle1" color="primary">
                              {service.price} {post.salary_currency}
                            </Typography>
                          </StyledListElement>
                          {index !== arr.length - 1 && <Divider />}
                        </>
                      ))}
                    </StyledList>
                  </>
                </ListContainer>
              )}
              {!!post?.skills?.length && (
                <ListContainer>
                  <Typography variant="h6">Вештини и квалификации</Typography>
                  <StyledList>
                    {post.skills.map((skill, index, arr) => (
                      <>
                        <StyledListElement key={skill.name}>
                          <Typography variant="subtitle1">{skill.name}</Typography>
                        </StyledListElement>
                        {index !== arr.length - 1 && <Divider />}
                      </>
                    ))}
                  </StyledList>
                </ListContainer>
              )}
            </SkillsAndServicesContainer>

            <Divider />
          </>
        )}

        {!!post?.portfolios?.length && (
          <>
            <Typography variant="h6">Портфолио</Typography>
            <Portfolios portfolios={post?.portfolios} />
            <Divider />
          </>
        )}

        {!!post?.workExperiences?.length && (
          <>
            <Typography variant="h6">Работно искуство</Typography>
            <ListContainer>
              <StyledList>
                {post.workExperiences.map((workExperience, index, arr) => (
                  <>
                    <StyledListElement key={workExperience.id}>
                      <WorkExperienceInfo>
                        <Typography
                          variant="subtitle1"
                          fontSize="1.5rem"
                          fontWeight="bold"
                          color="primary"
                        >
                          {workExperience.position}
                        </Typography>
                        <Typography variant="subtitle1" fontSize="1.2rem">
                          {workExperience.company_name}
                        </Typography>
                        <Typography variant="subtitle2" color="GrayText">
                          {dayjs(workExperience.start_date).format("MMM YYYY")} -{" "}
                          {workExperience.end_date
                            ? dayjs(workExperience.end_date).format("MMM YYYY")
                            : "Сега"}
                        </Typography>
                        <Typography variant="subtitle1" fontSize="1.2rem">
                          {workExperience.description}
                        </Typography>
                      </WorkExperienceInfo>
                    </StyledListElement>
                    {index !== arr.length - 1 && <Divider />}
                  </>
                ))}
              </StyledList>
            </ListContainer>
            <Divider />
          </>
        )}
        {hasSocialLinks && (
          <>
            <Typography variant="h6">Социјални мрежи</Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "30px",
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                "@media (max-width: 768px)": {
                  gap: "20px",
                },
              }}
            >
              {post?.facebook && (
                <LinkWithIcon
                  icon={<Facebook />}
                  text="Facebook"
                  to={
                    post?.facebook.includes("http") ? post?.facebook : `https://${post?.facebook}`
                  }
                  hideMobileText
                />
              )}
              {post?.twitter && (
                <LinkWithIcon
                  icon={<Twitter />}
                  text="Twitter"
                  to={post?.twitter.includes("http") ? post?.twitter : `https://${post?.twitter}`}
                  hideMobileText
                />
              )}
              {post?.linkedin && (
                <LinkWithIcon
                  icon={<LinkedIn />}
                  text="LinkedIn"
                  to={
                    post?.linkedin.includes("http") ? post?.linkedin : `https://${post?.linkedin}`
                  }
                  hideMobileText
                />
              )}
              {post?.github && (
                <LinkWithIcon
                  icon={<GitHub />}
                  text="GitHub"
                  to={post?.github.includes("http") ? post?.github : `https://${post?.github}`}
                  hideMobileText
                />
              )}
            </Box>
          </>
        )}
        {/* {!isOwnPost && !showConnectButton && breakpoint === "mobile" && (
          <Button
            variant="contained"
            color="primary"
            disabled={sendFriendRequest.isLoading || sendFriendRequest.isSuccess}
            to={!user ? "/login" : undefined}
            onClick={() => user && post?.id && sendFriendRequest.mutate({ postId: `${post?.id}` })}
          >
            Поврзи се
          </Button>
        ) : breakpoint === "mobile" ? (
          <Button
            color="primary"
            sx={{
              maxWidth: "200px",
              width: "200px",
            }}
            disabled={true}
            to={!user ? "/login" : undefined}
            onClick={() => user && post?.id && sendFriendRequest.mutate({ postId: `${post?.id}` })}
          >
            Испратена покана
          </Button>
        )} */}
        {isOwnPost && breakpoint === "mobile" && (
          <Button variant="contained" color="primary" to={`/posts/${post?.id}/edit`}>
            Измени
          </Button>
        )}
        {relatedPosts && (
          <RelatedPostsContainer>
            <PageContainer>
              <Typography
                variant="h4"
                fontWeight="bold"
                color="white"
                sx={{
                  marginBottom: "20px",
                  "@media (max-width: 768px)": {
                    fontSize: "1.5rem",
                  },
                }}
              >
                Слични огласи
              </Typography>
              <WorkerCards posts={relatedPosts} isCarousel={true} variant="default" />
            </PageContainer>
          </RelatedPostsContainer>
        )}
      </PostDetailsContainer>
    </PageContainer>
  );
};

export default PostDetails;
